import React from 'react'
import '../styles/navi.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Unit from './Unit';

const Product_approve = () => {
  return (
    <>
      <Unit 
        title={"Set Approval Workflows"}
        body={"Approval workflows makes approval process across teams faster and more efficient."}
        titleColor={"white"}
        bodyColor={"white"}
        background={"linear-gradient(to right,#AA1212,#FF43B4)"}
        imageSrc={process.env.PUBLIC_URL + "/images/404.png"}
      /> 
      <Unit 
        title={"Track the Approval Process"}
        body={"Get notified when the request is approved by any approver in the approval workflow."}
        titleColor={"black"}
        bodyColor={"black"}
        background={"linear-gradient(to right,#C7F3FF,#C7F3FF)"}
        imageSrc={process.env.PUBLIC_URL + "/images/S44.png"}
      /> 
    </>
)}

export default Product_approve;
import React from 'react'
import '../styles/navi.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Unit from './Unit';

const Assisted_Purchasing = () => {
  return (
    <>
      <Unit
        title={"Expert SAAS Negotiators"}
        body={"Get the best deals on your existing contracts or new purchases by reaching out to our expert SAAS negotiators"}
        titleColor={"white"}
        bodyColor={"white"}
        background={"linear-gradient(to right,#E14E0D,#FFF133)"}
        imageSrc={process.env.PUBLIC_URL + "/images/WhatsApp Image 2023-06-13 at 10.09.01 PM.png"}
      /> 
      <Unit
        title={"Get Immediate Assistance On Chat Or Call"}
        body={"Our expert negotiators will assist you in procurement process to expedite the purchase."}
        titleColor={"white"}
        bodyColor={"white"}
        background={"linear-gradient(to right,#5715C9,#5715C9)"}
        imageSrc={process.env.PUBLIC_URL + "/images/WhatsApp Image 2023-06-13 at 10.09.01 PM (1).png"}
      /> 
    </>
  )
}

export default Assisted_Purchasing;
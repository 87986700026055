import React from 'react'
import '../styles/navi.css';
import Unit from './Unit';

const Home = () => {
  return (
  <>
    <Unit 
      title={"Reduce Your Software Costs by 30%"}
      body={"Companies of all sizes uses ProcSaaS Buying Platform to manage their SaaS Buying Process from discovery to renewal management with data backed insights & experts SaaS Buyers"}
      titleColor={"black"}
      bodyColor={"black"}
      background={"linear-gradient(to right,#D64AFF, #FFDF10 )"}
      imageSrc={process.env.PUBLIC_URL + "/images/S 2@2x.jpg"}
    />

    <Unit 
      title={"Find the right SaaS Software"}
      body={"Market intelligence, benchmark data and the guidance you need to find the right platforms for your software stack. ​"}
      titleColor={"#FF9A26"}
      bodyColor={"white"}
      background={"linear-gradient(to right, #282B5F, #282B5F)"}
      imageSrc={process.env.PUBLIC_URL + "/images/S 2.jpg"}
    />

    <Unit 
      title={"Buy & Renew SaaS Software for a better price in less time"}
      body={"Get the best deals for your existing and upcoming contracts based on data-backed insights unique to ProcSaaS. See up to 5X ROI on what you pay us and a 30% decrease on your annual SaaS expense. ​"}
      titleColor={"black"}
      bodyColor={"black"}
      background={"white"}
      imageSrc={process.env.PUBLIC_URL + "/images/S 14.png"}
    />
    
    <Unit 
      title={"Manage your SaaS Stack like a Pro"}
      body={"Protect your business from lapsed renewals, duplicative purchases and shadow spend alongside a team of proactive buying experts who reinforce your ideal process. ​"}
      titleColor={"white"}
      bodyColor={"white"}
      background={"linear-gradient(to right,#2F188D,#2F188D)"}
      imageSrc={process.env.PUBLIC_URL + "/images/S 5.png"}
    />
   
    </>
    
    
    
  )
}

export default Home;